<template>
  <div id="Privilegios">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
        Privilégios
      </p>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- CONTAINER -------------------------------------------------------->
    <v-container class="container mt-n1 mx-0 pa-0 ">

      <!-- TABELA PRIVILEGIOS -------------------------------------------------------->
      <v-data-table
      class="mb-4 rounded-lg tabela"
      :items="dados_privilegios"
      :headers="headers_privilegios"
      :loading="loading"
      :items-per-page="10"
      hide-default-header
      hide-default-footer
      loading-text="Carregando...  aguarde..."
      no-data-text="Nenhum Registro Encontrado"
      no-results-text="Nenhum Registro Encontrado"
      >
        <template v-slot:header>
            <thead>
              <tr>
                <th v-for="h in headers_privilegios" :key="h.value" :class="h.class" class="py-2">
                  <span>{{h.text}}</span>
                </th>
              </tr>
            </thead>
        </template>

        <template #item="{ item }">
          <tr>
            <td class="ml-8 mt-4">
              <v-menu content-class="my-menu" 
                      transition="slide-y-transition"
                      bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <!-- Menu Editar / Excluir / etc -->
                  <v-list-item-group style="background-color:#F7F7F7">
                    <v-list-item
                      @click="store_Proposta.dialogMensagensCriacao = true">
                      <v-icon color="green" class="mr-2 icon-menu">
                          mdi-pencil
                      </v-icon>
                      Editar
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="true">
                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                      Excluir
                    </v-list-item>
                  </v-list-item-group>
                <!-- FIM Menu Editar / Excluir / etc -->

              </v-menu>
            </td>

            <td  style="width:250px!important">
              {{ item.descricao }}
            </td>

            <td class="">

              <v-col class="py-2 px-0">
                <v-select
                  v-model="attrArr"
                  :items="item.items"
                  item-text="title"
                  item-value="id"
                  chips
                  :label="item.title"
                  hide-details="auto"
                  background-color="transparent"
                  multiple
                  solo
                  dense
                  flat
                  deletable-chips
                  hide-selected
                  @change="changed()"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      :color="item.cor"
                      close
                      @click="select"
                      @click:close="remove(item.id)"
                      class="chips-font mb-1"
                    >
                      <strong>{{ item.title }}</strong>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </td>

          </tr> 
        </template>
      </v-data-table>

      <!-- FIM TABELA MENSAGENS -------------------------------------------------------->   

    </v-container>
    <!-- FIM CONTAINER -------------------------------------------------------->

    <v-divider class="divider mt-3"></v-divider>

    <v-footer color="transparent" elevation="0" class="footer justify-center pt-3">
      <v-btn
      id="btn_cancelar"
      ref="btn_cancelar"
      class="mr-4 caption font-weight-medium"
      color="primary"
      text
      >
        Cancelar
      </v-btn>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>   

  </div>
</template>

<script>
import { API } from "../../../services/API"

import store_Proposta from "../store_Proposta";
import { COR_SUBTITULO } from "../../../services/constantes";
import { sortBy } from "../../../services/funcoes"

export default {
  name: "Privilegios",

  components: {
  },

  data() {
    return {

      store_Proposta  : store_Proposta,
      COR_SUBTITULO     : COR_SUBTITULO,  
      
      valid: true,
      loading: false,

      /* TITULOS TABELA PRIVILEGIOS */
      headers_privilegios: [
        { text: "" },
        { text: "Aplicação", value: "Descrição", class: 'title-text' },
        { text: "Privilégios", value: "Privilegios", class: 'title-text' },
      ],

      attrArr: ["1.1", "1.2", "1.3", "1.4", "1.5",
                "2.1", "2.2", "2.3", "2.4", "2.5", "2.6",
                "3.3", "3.4", "3.5", "3.6",
                "4.3", "4.7",
                "6.1", "6.2", "6.3", "6.4",
                "10.1", "10.2",
                "13.1", "13.2", "13.6"],
      
      /* TABELA PRIVILEGIOS */
      dados_privilegios: [],
      // dados_privilegios: [
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:1.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:1.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:1.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:1.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:1.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:2.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:2.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:2.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:2.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:2.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:3.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:3.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:3.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:3.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:3.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:4.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:4.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:4.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:4.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:4.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:5.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:5.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:5.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:5.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:5.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:6.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:6.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:6.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:6.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:6.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:7.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:7.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:7.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:7.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:7.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:8.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:8.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:8.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:8.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:8.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:9.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:9.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:9.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:9.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:9.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:10.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:10.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:10.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:10.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:10.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:11.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:11.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:11.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:11.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:11.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Proposta / Venda",
      //    items:       [{id:12.1, title:'Visualizar',  cor:'#bbefef'},
      //                  {id:12.2, title:'Incluir',     cor:'#85d7bc'},
      //                  {id:12.3, title:'Editar',      cor:'#f0cf90'},
      //                  {id:12.4, title:'Excluir',     cor:'#e1acac'},
      //                  {id:12.5, title:'Liberar',     cor:'#f6f6c0'}]},
      //   {descricao: "Configurações do Sistema",
      //    items:       [{id:13.1, title:'Visualizar',     cor:'#bbefef'},
      //                  {id:13.2, title:'Incluir',        cor:'#85d7bc'},
      //                  {id:13.3, title:'Editar',         cor:'#f0cf90'},
      //                  {id:13.4, title:'Excluir',        cor:'#e1acac'},
      //                  {id:13.5, title:'Liberar',        cor:'#f6f6c0'},
      //                  {id:13.6, title:'Imprimir Dados', cor:'#f7f7f7'},
      //                  {id:13.7, title:'Aprovar Contrato', cor:'#f7f7f7'},
      //                  {id:13.8, title:'Aprovar Documentos', cor:'#f7f7f7'}]},
      // ],

    };
  },

  mounted() {
    //console.log("MOUNTED")
    this.busca_aplicacoes();
  },

  methods: {

    async busca_aplicacoes() {
      const response = await API.get("/configuracao/aplicacao", {});
      if (response.data.result.length > 0) {
        const apps = response.data.result.map(value => {
          let apps_evento = value.aplicacao_evento.map(value => {
            const apps_2 = {};
            apps_2.id = value.cod_aplicacao + '.' + value.evento_ordem;
            apps_2.title = value.evento_nome;
            value.evento_nome == 'Visualizar' ? apps_2.cor = '#bbefef' : '#f7f7f7';
            value.evento_nome == 'Incluir'    ? apps_2.cor = '#85d7bc' : '#f7f7f7';
            value.evento_nome == 'Alterar'    ? apps_2.cor = '#f0cf90' : '#f7f7f7';
            value.evento_nome == 'Excluir'    ? apps_2.cor = '#e1acac' : '#f7f7f7';
            value.evento_nome == 'Liberar'    ? apps_2.cor = '#f6f6c0' : '#f7f7f7';
            return apps_2;
          })
          return { descricao: value.nome, items: apps_evento};
        })
      this.dados_privilegios = apps;
      }
    },

    remove (id) {
      let idx = this.attrArr.indexOf(id)
      this.attrArr.splice(idx, 1)
      this.attrArr = [...this.attrArr]
    },

    async changed(){
      //console.log(this.attrArr)
      sortBy(this.attrArr, { prop: "id" });
      this.$emit('changed', this.attrArr)
    },

  },

};
</script>

<style scoped>

#Privilegios {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #Privilegios {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#Privilegios::-webkit-scrollbar {
  width: 5px;
}

#Privilegios::-webkit-scrollbar-button {
  padding: 1px;
}

#Privilegios::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#Privilegios::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #Privilegios::-webkit-scrollbar {
    width: initial;
  }

  #Privilegios::-webkit-scrollbar-button {
    padding: initial;
  }

  #Privilegios::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #Privilegios::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
  height: calc(100% - 125px);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.chips-font {
  font-weight: 300;
  height: 20px!important;
}

.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  left: 10px;
  transform: translateY(-100%);
  width: 10px; 
  height: 13px; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #F7F7F7;
}

</style>
