<template>
  <div id="MensagensRemontagem">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
      Mensagens
      </p>
      <v-btn 
      @click="dados_editar = {}; dialogMensagensCriacaoModal = true" 
      class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
      </v-btn>

    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- CONTAINER -------------------------------------------------------->
    <v-container class="container mt-n1 mx-0 pa-0 ">

      <!-- TABELA MENSAGENS -------------------------------------------------------->
      <v-data-table
      class="mb-4 rounded-lg tabela"
      :items="dados_mensagens"
      :headers="headers_mensagens"
      style="white-space: nowrap"
      :loading="loading"
      :items-per-page="10"
      hide-default-footer
      loading-text="Carregando...  aguarde..."
      no-data-text="Nenhum Registro Encontrado"
      no-results-text="Nenhum Registro Encontrado">
        <template #item="{ item }">
          <tr :style="{ 'background-color' : !item.empreendvendamsg_encerrado_dt ?  'rgb(255 200 194 / 56%)' : ''}">
            <td style="max-width: 35px; display: flex; justify-content: center;" class="pr-0" >
              <v-menu flat  content-class="elevation-0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <!-- Menus Editar / Excluir / etc -->
                <v-card class="ml-8 mt-4" >
                  <v-list-item-group style="background-color:#F7F7F7">
                    <v-list-item
                      @click="editar(item)">
                      <v-icon color="green" class="mr-2 icon-menu">
                          mdi-pencil
                      </v-icon>
                      Editar
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="dialog_excluir = item">
                      <v-icon
                        color="red"
                        class="mr-2 icon-menu">
                        mdi-delete
                      </v-icon>
                      Excluir
                    </v-list-item>
                    <v-list-item
                      v-if="!item.empreendvendamsg_encerrado_dt"
                      @click="empreendvendamsg_encerrado_dt = moment().format(); dialog_encerrar = item">
                      <v-icon
                        color="gray"
                        class="mr-2 icon-menu">
                        mdi-message-off-outline
                      </v-icon>
                      Encerrar mensagem
                    </v-list-item>
                  </v-list-item-group>
                </v-card>
                <!-- --------------------------- -->

              </v-menu>
            </td>
            <td>
              {{ item.cod_empreendvenda_msg }}
            </td>
            <td>
              {{ item.categoria }}
            </td>
            <td>
              {{ item.empreendvendamsg_assunto }}
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span style="font-size:12px"  v-bind="attrs"
                    v-on="on">{{ item.empreendvendamsg_texto.substring(0, 35) }}{{  item.empreendvendamsg_texto.length > 35 ? '...' : '' }}</span>
                </template>
                <span style="font-size:12px">{{ item.empreendvendamsg_texto }}</span>
              </v-tooltip>
            </td>
            <td>
              {{ item.empreendvendamsg_dt ? formatDate(item.empreendvendamsg_dt) : '' }}
            </td>
            <td>
              {{ item.empreendvendamsg_encerrado_dt ? formatDate(item.empreendvendamsg_encerrado_dt) : '' }}
            </td>
          </tr> 
        </template>
      </v-data-table>

      <v-container v-if="dialogMensagensCriacaoModal">
        <v-dialog
        v-model="dialogMensagensCriacaoModal"
        transition="dialog-bottom-transition"
        max-width="400"
        class="pa-0"
        >
          <MensagensCriacaoModal
            :dados_editar="dados_editar"
            @busca="dadosMensagens"
            @fecharModal="dialogMensagensCriacaoModal = false" />
        </v-dialog>

      </v-container>
      <!-- FIM TABELA MENSAGENS -------------------------------------------------------->   

    </v-container>
    <!-- FIM CONTAINER -------------------------------------------------------->

    <!-- <v-divider class="divider mt-3"></v-divider> -->

       <!--------- DIALOG EXCLUIR DOCUMENTO ------------------------------->
       <v-container v-if="dialog_excluir">
        <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
    
          <v-card elevation="0" class="">
            <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="white--text title-page body-1">Excluir mensagem</span>
              <v-btn @click="dialog_excluir = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
            <v-card-title class="text-h6" style="word-break: keep-all">
              Tem certeza que deseja EXCLUIR esta mensagem?
            </v-card-title>
            <v-card-text>
              <span style="font-size: 15px;"><b>Categoria:</b> {{dialog_excluir.categoria}}</span><br>
              <span style="font-size: 15px;"><b>Assunto:</b> {{dialog_excluir.empreendvendamsg_assunto}}</span><br>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
                Cancelar
              </v-btn>
              <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="excluir()">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <!-- FIM DIALOG EXCLUIR MENSAGEM  -->
     
      <!--------- DIALOG ENCERRAR MENSAGEM ------------------------------->
       <v-container v-if="dialog_encerrar">
        <v-dialog v-model="dialog_encerrar" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
    
          <v-card elevation="0" class="">
            <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="white--text title-page body-1">Encerrar mensagem</span>
              <v-btn @click="dialog_encerrar = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
            <!-- <v-card-title class="text-h6" style="word-break: keep-all">
              Tem certeza que deseja EXCLUIR esta mensagem?
            </v-card-title> -->
            <v-card-text class="mt-3">
              <span style="font-size: 15px;"><b>Categoria:</b> {{dialog_encerrar.categoria}}</span><br>
              <span style="font-size: 15px;"><b>Assunto:</b> {{dialog_encerrar.empreendvendamsg_assunto}}</span><br>
              <DatePicker
                v-model="empreendvendamsg_encerrado_dt"
                label="Data de Encerramento"
                placeholder="dia/mês/ano"
                class="mr-0 mt-3 mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                format="DD/MM/YYYY"
                ></DatePicker>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn
                id="btn_cancelar"
                ref="btn_cancelar"
                class="mr-4"
                color="primary"
                text
                @click="dialog_encerrar = false">
                Cancelar
              </v-btn>
              <v-btn
                class="btn white--text"
                color="primary accent-4"
                :disabled="!validateDate(empreendvendamsg_encerrado_dt)"
                :loading="loading"
                @click="encerrar()">
                Encerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <!-- FIM DIALOG EXCLUIR DOCUMENTO  -->

  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import store_site from "../../../store/store_site"
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import MensagensCriacaoModal from "./MensagensCriacaoModal.vue";
import {API} from "../../../services/API"
import { formatDate, validateDate } from "../../../services/funcoes";
import moment from "moment";

import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";

export default {
  name: "MensagensRemontagem",

  components: {
    MensagensCriacaoModal,
    DatePicker,
  },

  data() {
    return {

      store_Proposta  : store_Proposta,
      store_site      : store_site,
      COR_SUBTITULO   : COR_SUBTITULO,  
      COR_PRINCIPAL   : COR_PRINCIPAL,
      API             : API,
      formatDate      : formatDate,
      validateDate    : validateDate,

      dialogMensagensCriacaoModal : false,
      dialog_excluir              : false,
      dialog_encerrar             : false,
      
      empreendvendamsg_encerrado_dt : moment().format(),
      valid: true,
      loading: false,

      /* TITULOS TABELA MENSAGENS */
      headers_mensagens: [
        { text: "#",                value: "Código",                        class: 'title-text pr-0', sortable: false },
        { text: "Categoria",        value: "categoria",                     class: 'title-text', sortable: true },
        { text: "Descrição",        value: "empreendvendamsg_assunto",      class: 'title-text', sortable: true },
        { text: "Mensagem",         value: "empreendvendamsg_texto",        class: 'title-text', sortable: true },
        { text: "Data",             value: "empreendvendamsg_dt",           class: 'title-text', sortable: true },
        { text: "Dt. Encerramento", value: "empreendvendamsg_encerrado_dt", class: 'title-text', sortable: true },
      ],

      /* TABELA MENSAGENS */
      dados_mensagens: [],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
    };
  },

  watch: {
    'store_Proposta.proposta_selecionado'(val){
      this.atualizaAbas()
    }
  },

  mounted(){
    this.dadosMensagens()
  },

  methods: {
    async atualizaAbas(){
      if(!!this.store_Proposta.proposta_selecionado?.cod_empreendvenda && this.store_Proposta.proposta_selecionado?.menu_abas){
        let abas = await this.store_Proposta.menuAbas()
        this.store_Proposta.abas = abas
      }
    },

    async encerrar(){
      this.dialog_encerrar.empreendvendamsg_encerrado_dt = this.empreendvendamsg_encerrado_dt
      this.loading = true
      const resposta = await API.put(`empreend_venda_mensagem_encerra/${this.dialog_encerrar.cod_empreendvenda_msg}`,  JSON.stringify(this.dialog_encerrar))
      this.dialog_encerrar = false

      if (resposta.status == 200) {
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = resposta.data.result.result;
        this.store_site.alert = true;

        this.dadosMensagens()

      } else {
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = resposta.errors;
        this.store_site.alert = true;
      }
      this.loading = false
    },

    async excluir(){
      this.loading = true
      const resposta = await API.delete(`empreend_venda_mensagem/${this.dialog_excluir.cod_empreendvenda_msg}`)
      this.dialog_excluir = false

      if (resposta.status == 200) {
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = resposta.data.result.result;
        this.store_site.alert = true;

        this.dadosMensagens()

      } else {
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = resposta.errors;
        this.store_site.alert = true;
      }
      this.loading = false
    },

    editar(item){
      this.dados_editar = item
      this.dados_editar.acao = 'editar'
      this.dialogMensagensCriacaoModal = true
      
    },

    async dadosMensagens(){
      const {data} = await API.get(`empreend_venda_mensagem`)
      this.dados_mensagens = data.result

      var result = await API.get(`menu_abas_proposta`,{params:{cod_empreendvenda : this.$route.params.cod_empreendvenda}})
      this.store_Proposta.proposta_selecionado.menu_abas = result.data.result || []

      this.atualizaAbas()
    }
  },
};
</script>

<style scoped>
#MensagensRemontagem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #MensagensRemontagem {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#MensagensRemontagem::-webkit-scrollbar {
  width: 5px;
}

#MensagensRemontagem::-webkit-scrollbar-button {
  padding: 1px;
}

#MensagensRemontagem::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#MensagensRemontagem::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #MensagensRemontagem::-webkit-scrollbar {
    width: initial;
  }

  #MensagensRemontagem::-webkit-scrollbar-button {
    padding: initial;
  }

  #MensagensRemontagem::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #MensagensRemontagem::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
  height: calc(100% - 125px);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}
</style>
