<template>
  <div id="FinanceiroRemontagem">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2 mb-2">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
        Financeiro
      </p>

      <v-btn 
      @click="store_Proposta.dialogMensagensCriacao = true" 
      class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon mr-1" color="green accent-4">mdi-pencil-outline</v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Editar</span>
      </v-btn>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <v-row class="mb-1 pa-0">
      <v-col cols="4" class="pr-0">
        <v-card elevation="0" class="rounded-lg tabela">
          <!-- TABELA ENTRADAS -------------------------------------------------------->
          <v-data-table
          class="mb-4"
          :items="dados_entradas"
          :headers="headers_entradas"
          :loading="loading"
          :items-per-page="10"
          hide-default-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <template v-slot:top>
              <v-toolbar
              flat
              class="mb-n2 rounded-lg"
              >
                <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
                  Entrada(s)
                </p>  
              </v-toolbar>
            </template>

            <template v-slot:header>
                <thead>
                  <tr>
                    <th v-for="h in headers_entradas" :key="h.value" :class="h.class" class="py-2">
                      <span>{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
            </template>

            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.parcelas }}
                </td>

                <td>
                  {{ item.vencimento }}
                </td>

                <td>
                  {{ item.valor }}
                </td>
              </tr> 
            </template>
          </v-data-table>

          <!-- RODAPE ENTRADAS -------------------------------------------------------->
          <div class="mt-2 mb-1 ml-4">
            <table class="d-flex justify-end">
              <tbody style="width: 75%">
                <tr class="text sticky-table-footer-desktop">
                  <td align="left" style="width: 50%; background-color:#FFFFFF"><span class="text-left">Min. permitido</span></td>
                  <td align="center" style="width: 50%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.min_permitido }}</span></td>
                </tr>

                <tr class="text sticky-table-footer-desktop">
                  <td align="left" style="width: 50%; background-color:#FFFFFF"> <span class="text-left">Total do Cliente (Parc)</span></td>
                  <td align="center" style="width: 50%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total_cliente }}</span></td>
                </tr>

                <tr class="text sticky-table-footer-desktop">
                  <td align="left" style="width: 50%; background-color:#FFFFFF"> <span class="text-left">+Sinal</span></td>
                  <td align="center" style="width: 50%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.sinal }}</span></td>
                </tr>

                <tr class="text sticky-table-footer-desktop">
                  <td align="left" style="width: 50%; background-color:#FFFFFF"><span class="text-left">Restante</span></td>
                  <td align="center" style="width: 50%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.restante }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- FIM RODAPE ENTRADAS -------------------------------------------------------->
        </v-card>
      </v-col>

      <v-col cols="4" class="px-2">
        <v-card elevation="0" class="rounded-lg tabela">
          <!-- TABELA PARCELAS -------------------------------------------------------->
          <v-data-table
          class="mb-4"
          :items="dados_parcelas"
          :headers="headers_parcelas"
          :loading="loading"
          :items-per-page="10"
          hide-default-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <template v-slot:top>
              <v-toolbar
              flat
              class="mb-n2 rounded-lg"
              >
                <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
                  Parcela(s)
                </p>  
              </v-toolbar>
            </template>

            <template v-slot:header>
                <thead>
                  <tr>
                    <th v-for="h in headers_parcelas" :key="h.value" :class="h.class" class="py-2">
                      <span>{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
            </template>

            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.tipo }}
                </td>

                <td>
                  {{ item.quantidade }}
                </td>

                <td>
                  {{ item.vencimento }}
                </td>

                <td>
                  {{ item.valor }}
                </td>
              </tr> 
            </template>
          </v-data-table>

          <!-- RODAPE PARCELAS -------------------------------------------------------->
          <div class="mt-2 mb-1 ml-4">
            <table class="d-flex justify-end mr-4">
              <tbody style="width: 50%">
                <tr class="text sticky-table-footer-desktop">
                  <td align="left" style="width: 50%; background-color:#FFFFFF"><span class="text-left">Saldo</span></td>
                  <td align="center" style="width: 50%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.saldo }}</span></td>
                </tr>

                <tr class="text sticky-table-footer-desktop">
                  <td align="left" style="width: 50%; background-color:#FFFFFF"> <span class="text-left">Total</span></td>
                  <td align="center" style="width: 50%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
            <!-- FIM RODAPE PARCELAS -------------------------------------------------------->
        </v-card>
      </v-col>

      <v-col cols="4" class="pl-0">
        <v-card elevation="0" class="rounded-lg tabela">
          <!-- TABELA ITENS ADICIONAIS -------------------------------------------------------->
          <v-data-table
          class="mb-4"
          :items="dados_itens_adicionais"
          :headers="headers_itens_adicionais"
          :loading="loading"
          :items-per-page="10"
          hide-default-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <template v-slot:top>
              <v-toolbar
              flat
              class="mb-n2 rounded-lg"
              >
                <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
                  Itens adicionais da Proposta
                </p>  
              </v-toolbar>
            </template>
            <template v-slot:header>
                <thead>
                  <tr>
                    <th v-for="h in headers_itens_adicionais" :key="h.value" :class="h.class" class="py-2">
                      <span>{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
            </template>

            <template #item="{ item }">
              <tr>
                <td >
                  {{ item.item }}
                </td>

                <td>
                  {{ item.valor }}
                </td>
              </tr> 
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    
    <v-divider class="divider"></v-divider>

    <v-row class="mt-1 mx-0 pa-0 pt-3 container">
      <v-card elevation="0" class="rounded-lg tabela">
        <!-- TABELA FINANCEIRO -------------------------------------------------------->
        <v-data-table
        v-model="selected"
        class="mb-2 tabela-financeiro"
        :headers="headers_financeiro"
        :items="dados_financeiro"
        :loading="loading"
        :items-per-page="10"
        show-select
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
        >
          <template v-slot:top>
            <v-toolbar
            flat
            class="mb-n2 rounded-lg"
            >
              <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
                Plano de pagamento
              </p>

              <v-spacer></v-spacer>

                    <v-menu
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-2"
                        >
                          <v-list-item-title
                            v-if="item.title == 'Imprimir boleto normal'"
                            class="body-2"
                          >
                            <v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                              >mdi-printer
                            </v-icon>
                            {{ item.title }}
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="item.title == 'Imprimir boleto antecipação'"
                            class="body-2"
                            ><v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                              >mdi-printer</v-icon
                            >
                            {{ item.title }}
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="item.title == 'Aniversário / reajuste'"
                            class="body-2"
                            ><v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                              >mdi-pencil-outline</v-icon
                            >
                            {{ item.title }}
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="item.title == 'Alterar vencto parcelas'"
                            class="body-2"
                            ><v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                              >mdi-pencil-outline</v-icon
                            >
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
            </v-toolbar>
          </template>

          <template v-slot:header>
              <thead>
                <tr>
                  <th v-for="h in headers_financeiro" :key="h.value" :class="h.class" class="py-2">
                    <span>{{h.text}}</span>
                  </th>
                </tr>
              </thead>
          </template>

          <template #item="{ item }">
            <tr>
              <td>
                <v-checkbox
                hide-details
                class="checkbox mr-n6"
                />
              </td>

              <td>
                {{ item.situacao }}
              </td>

              <td>
                {{ item.cobranca }}
              </td>

              <td >
                {{ item.via }}
              </td>

              <td>
                {{ item.parcela }}
              </td>

              <td >
                {{ item.vencimento }}
              </td>

              <td>
                {{ item.valor }}
              </td>

              <td>
                {{ item.acréscimo }}
              </td>

              <td >
                {{ item.valor_final }}
              </td>

              <td>
                {{ item.desconto }}
              </td>

              <td >
                {{ item.dt_pagamento }}
              </td>

              <td>
                {{ item.valor_pagamento }}
              </td>

              <td >
                {{ item.transferencia }}
              </td>

              <td>
                {{ item.meses }}
              </td>
            </tr> 
          </template>
        </v-data-table>
        <!-- FIM TABELA FINANCEIRO -------------------------------------------------------->

        <!-- RODAPE FINANCEIRO -------------------------------------------------------->
        <div class="mt-2 mb-1 ml-4">
          <table class="d-flex justify-end">
            <tbody class="" style="width: 60%">
              <tr class="text sticky-table-footer-desktop">
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="text-left">Total Pago (Entr)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total_pago_entr }}</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="text-left">Parc (Totais)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF">{{ dados.parc_totais }}</td>
              </tr>

              <tr class="text sticky-table-footer-desktop">
                <td align="left" style="width: 15%; background-color:#FFFFFF"> <span class="text-left">Total Pago (Parc)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total_pago_parc }}</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="text-left">Parc (Pagas)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF">{{ dados.parc_pagas }}</td>
              </tr>

              <tr class="text sticky-table-footer-desktop">
                <td align="left" style="width: 15%; background-color:#FFFFFF"> <span class="text-left">Total Pago (Antec)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total_pago_antec }}</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="text-left">Parc (Aberto/Atraso)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF">{{ dados.parc_aberto_atraso }}</td>
              </tr>

              <tr class="text sticky-table-footer-desktop">
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="text-left">Total Pago (Outros)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total_pago_outros }}</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="text-left">Acrésc (Multa/Juros)</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF">{{ dados.acrescimo }}</td>
              </tr>

              <tr class="text sticky-table-footer-desktop">
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="font-weight-bold text-left">Total Pago</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="col-font-sumario">{{ dados.total_pago }}</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF"><span class="font-weight-bold text-left">Total Quitar</span></td>
                <td align="left" style="width: 15%; background-color:#FFFFFF">{{ dados.total_quitar }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- FIM RODAPE FINANCEIRO -------------------------------------------------------->
      </v-card>

    </v-row>

  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import { COR_SUBTITULO } from "../../../services/constantes";
import { formatNumber } from "../../../services/funcoes";

export default {
  name: "FinanceiroRemontagem",

  components: {
  },

  data() {
    return {

      store_Proposta    : store_Proposta,
      COR_SUBTITULO     : COR_SUBTITULO,  
      formatNumber      : formatNumber,
      
      selected: [],
      loading: false,

      dados:
        {
          min_permitido: "35.145,60",
          total_cliente: "35.145,60",
          sinal: "35.145,60",
          restante: "",

          saldo: "85.800,00",
          total: "120.773,52",

          total_pago_entr: "21.450,00",
          parc_totais: "73",
          total_pago_parc: "3.354,82",
          parc_pagas: "73",
          total_pago_antec: "84.153,17",
          parc_aberto_atraso: "000 / 000",
          total_pago_outros: "84.153,17",
          acrescimo: "",  
          total_pago: "108.957,99",
          total_quitar: "0,00",
        },

      /* TITULOS TABELA ENTRADAS */
      headers_entradas: [
        { text: "Parc.", value: "Parc.", class: 'title-text' },
        { text: "Vencto.", value: "Vencto.", class: 'title-text' },
        { text: "Valor", value: "Valor", class: 'title-text' },
      ],

      /* TABELA ENTRADAS */
      dados_entradas: [
        {parcelas: "1", vencimento: "27/01/2022", valor: "17.550,00", },
      ],

      /* TITULOS TABELA PARCELAS */
      headers_parcelas: [
        { text: "Tipo", value: "Tipo", class: 'title-text' },
        { text: "Qtde.", value: "Qtde.", class: 'title-text' },
        { text: "Vencto.", value: "Vencto.", class: 'title-text' },
        { text: "Valor", value: "Valor", class: 'title-text' },
      ],

      /* TABELA PARCELAS */
      dados_parcelas: [
        {tipo: "Mensal", quantidade: "48", vencimento: "10/04/2022", valor: "1.697,22" },
      ],

      /* TITULOS TABELA ITENS ADICIONAIS */
      headers_itens_adicionais: [
        { text: "Item", value: "Item", class: 'title-text' },
        { text: "Valor.", value: "Valor", class: 'title-text' },
      ],

      /* TABELA ITENS ADICIONAIS */
      dados_itens_adicionais: [
        {item: "", valor: "" },
      ],

      /* TITULOS TABELA FINANCEIRO */
      headers_financeiro: [
        { text: "" },
        { text: "Situação", value: "Situação", class: 'title-text' },
        { text: "Cobrança", value: "Cobrança", class: 'title-text' },
        { text: "Via", value: "Via", class: 'title-text' },
        { text: "Parcela", value: "Parcela", class: 'title-text' },
        { text: "Vencto.", value: "Vencto.", class: 'title-text' },
        { text: "Valor", value: "Valor", class: 'title-text' },
        { text: "Acréscimo", value: "Acréscimo", class: 'title-text' },
        { text: "Vl. Final", value: "Vl. Final", class: 'title-text' },
        { text: "Desconto", value: "Desconto", class: 'title-text' },
        { text: "Dt. Pagto.", value: "Dt. Pagto.", class: 'title-text' },
        { text: "Vl. Pagto.", value: "Vl. Pagto.", class: 'title-text' },
        { text: "Transf. (Cedente)", value: "Transf. (Cedente)", class: 'title-text' },
        { text: "Meses", value: "Meses", class: 'title-text' },
      ],

      /* TABELA FINANCEIRO */
      dados_financeiro: [
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },
        {
          situacao: "Pago", 
          cobranca: "56553",
          via: "0", 
          parcela: "Entr(1/1)", 
          vencimento: "05/03/2022", 
          valor: "82.000,00", 
          acrescimo: "", 
          valor_final: "82.000,00",
          desconto: "",
          dt_pagamento: "04/01/2022",
          valor_pagamento: "82.000,00",
          transferencia: "",
          meses: "",
        },

      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Imprimir boleto normal",
        },
        {
          title: "Imprimir boleto antecipação",
        },
        {
          title: "Aniversário / reajuste",
        },
        {
          title: "Alterar vencto parcelas",
        },
      ],

    };
  },
};
</script>

<style scoped>
#FinanceiroRemontagem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #FinanceiroRemontagem {
    padding-right: 0px;
  }
}

.separador {
  color: #505050!important;
  opacity: 0.6;
}

/* ---------- BARRA DE SCROLL ---------- */
#FinanceiroRemontagem::-webkit-scrollbar {
  width: 5px;
}

#FinanceiroRemontagem::-webkit-scrollbar-button {
  padding: 1px;
}

#FinanceiroRemontagem::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#FinanceiroRemontagem::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #FinanceiroRemontagem::-webkit-scrollbar {
    width: initial;
  }

  #FinanceiroRemontagem::-webkit-scrollbar-button {
    padding: initial;
  }

  #FinanceiroRemontagem::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #FinanceiroRemontagem::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.checkbox {
  margin-top: -3px;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.tabela-financeiro {
  max-height: 450px;
}

</style>
